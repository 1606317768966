// Frontend: React component for file upload using TailwindCSS

import React, { useState } from 'react';
import axios from 'axios';

const FileUpload = ({ password, question_id, ReloadFunction }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) return;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('question_id', question_id);
    try {
      await axios.post('https://uni.xdd.ro/api/upload', formData, {
        headers: {
          'Authorization': `Bearer ${password}`, 
          'Content-Type': 'multipart/form-data',

        },
      });
      alert('File uploaded successfully');
      ReloadFunction();
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file');
    }
  };

  return (
    <div className="p-4">
      <input
        type="file"
        onChange={handleFileChange}
        className="block w-full text-sm text-gray-500
          file:mr-4 file:py-2 file:px-4
          file:rounded-full file:border-0
          file:text-sm file:font-semibold
          file:bg-violet-50 file:text-violet-700
          hover:file:bg-violet-100"
      />
      <button
        onClick={handleUpload}
        className="mt-2 px-4 py-2 bg-blue-500 text-white font-bold rounded hover:bg-blue-700 transition duration-300"
      >
        Upload
      </button>
    </div>
  );
};

export default FileUpload;
