import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Test = () => {
    let { question_set_id } = useParams();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [userAnswers, setUserAnswers] = useState({});
    const [showResults, setShowResults] = useState(false);
    const [score, setScore] = useState(0);
    const [questionDetails, setQuestionDetails] = useState([]);

    useEffect(() => {
      fetch('https://uni.xdd.ro/api/questions?set=' + question_set_id)
        .then(response => response.json())
        .then(data => {
          const formattedQuestions = data.map(q => ({
            question_id: q.question_id,
            questionText: q.question_text,
            hasCorrectAnswer: q.hasCorrectAnswer,
            file: q.file,
            options: q.answers.map(a => ({ id: a.answer_id, text: a.answer_text })),
          }));
          setQuestions(formattedQuestions);
        })
        .catch(error => {
          console.error('Error fetching questions:', error);
        });
    }, []);
    
  

    const handleAnswerChange = (questionId, optionId) => {
      console.log(`Question ID: ${questionId}, Option ID: ${optionId}`);
      setUserAnswers({
        ...userAnswers,
        [questionId]: {
          ...userAnswers[questionId],
          [optionId]: !userAnswers[questionId]?.[optionId],
        },
      });
    };
    

  const nextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleTestSubmission();
    }
  };

  const prevQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };
  const handleTestSubmission = () => {
    // Preparing answers for submission
    console.log("User answers:", userAnswers); // Log user answers

    // Sending formatted answers to the backend
    fetch('https://uni.xdd.ro/api/score', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userAnswers),
    })
    .then(response => response.json())
    .then(data => {
      setScore(data.score);
      setQuestionDetails(data.questionDetails); // Store question details received from the backend
      setShowResults(true);
    })
    .catch(error => {
      console.error('Error submitting answers:', error);
    });
  };
  
  const renderOptions = (question) => {
    if (!question) {
      return null; // Or some fallback UI
    }
    //console.log("Rendering options for question:", question); // Log question being rendered
    return question.options.map((option, index) => (
      <div key={index} className="flex items-center mb-4 p-2 border-b border-gray-200">
        <input
          type="checkbox"
          id={`option-${question.question_id}-${index}`}
          checked={userAnswers[question.question_id]?.[option.id] || false}
          onChange={() => handleAnswerChange(question.question_id, option.id)}
          className="mr-3 h-5 w-5"
        />
        <label htmlFor={`option-${question.question_id}-${index}`} className="text-lg text-gray-700">{option.text}</label>
        
      </div>
    ));
  };
  
  const renderTestContent = () => {
    const currentQuestion = questions[currentQuestionIndex];

    // Check if the current question is defined
    if (!currentQuestion) {
      return <p>Loading question...</p>; // Or some fallback UI
    }
    console.log("Rendering question:", currentQuestion); // Log question being rendered
    return (
      <div>
        <div className="mb-6 p-4 bg-white shadow rounded-lg">
          <p className="text-xl font-semibold text-gray-800">{currentQuestion.questionText}</p>
          {/* display currentQuestion.file.file_path if currentQuestion.file is not null */}
          {currentQuestion.file ? <img src={currentQuestion.file.file_path} alt="Question" className="my-4" /> : null }
          {renderOptions(currentQuestion)}
          {currentQuestion.hasCorrectAnswer
        ? <span className='text-green-400'>✓ This question has an answer in DB</span>
        : <span className='text-red-400'>✗ This question does not have an answer 
        in DB</span>}
        </div>
  
        <div className="flex justify-between mt-6">
          <button onClick={prevQuestion} className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg">
            Previous
          </button>
          <button onClick={nextQuestion} className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg">
            {currentQuestionIndex === questions.length - 1 ? 'Submit' : 'Next'}
          </button>
        </div>
      </div>
    );
  };
  
  const renderResults = () => {
    return (
        <div className="p-4 bg-white shadow rounded-lg text-center">
            <h2 className="text-3xl font-bold text-green-500 my-4">Your Score: {score}</h2>
            {questionDetails.map((q) => (
                <div className={`p-4 mb-4 ${q.isCorrect ? 'text-grey-200' : 'text-red-500 font-bold'} border ${q.isCorrect ? 'border-green-500' : 'border-red-500'} rounded-lg`}>
                    <p> <span className='font-bold'>{q.id}</span>: {q.text}</p>
                    {q.answers.map((a) => (
                        <p className={`my-1 font-normal ${a.userAnswer ? 'text-green-600' : 'text-red-500'}`}>
                            {a.text} {(a.isCorrect === a.userAnswer ? '✅' : '❌')}
                        </p>
                    ))}
                </div>
            ))}
        </div>
    );
};
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-center text-3xl font-bold text-blue-700 mb-8">Test</h1>
      {questions.length > 0 ? (
        showResults ? renderResults() : renderTestContent()
      ) : (
        <p className="text-center text-lg text-gray-500">Loading questions...</p>
      )}
    </div>
  );
  };

export default Test;
