// Importing required modules
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Home component
const Home = () => {
  const [questionSets, setQuestionSets] = useState([]);

  // Fetch question sets from the backend on component mount
  useEffect(() => {
    fetch('https://uni.xdd.ro/api/question-sets')
      .then(response => response.json())
      .then(data => setQuestionSets(data))
      .catch(error => console.error('Error fetching question sets:', error));
  }, []);

  return (
    <div className="container mx-auto px-4">
      <h1 className="text-center text-3xl font-bold my-6">College Test Platform</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-items-center">
        {questionSets.map(qs => (
          <div key={qs.id} className="max-w-sm w-full lg:max-w-full">
            <div
              className="relative overflow-hidden bg-cover bg-center bg-no-repeat transition-all duration-500 ease-in-out"
              style={{ backgroundImage: `url(${qs.home_image_path})` }}
              // onMouseEnter={e => e.currentTarget.style.filter = 'blur(8px)'}
              // onMouseLeave={e => e.currentTarget.style.filter = 'blur(4px)'}
            >
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center transition-opacity duration-500 ease-in-out">
                <span className="text-white text-xl p-4">{qs.name}</span>
              </div>
              <div className="absolute inset-0 bg-black bg-opacity-0 hover:bg-opacity-50 flex items-center justify-center transition-opacity duration-500 ease-in-out">
                <span className="text-white text-xl p-4 opacity-0 hover:opacity-100 transition duration-300 ease-in-out">{qs.description}</span>
              </div>
              <div style={{ height: '200px' }}></div>
            </div>
            <Link to={`/test/${qs.id}`} className="block text-center bg-green-600 text-white py-2 px-4 rounded-b hover:bg-green-800 transition duration-300 ease-in-out">
              Start Test
            </Link>
          </div>
        ))}
      </div>
      <div className="flex flex-col items-center">
        <Link to="/admin" className="bg-blue-500 text-white py-2 px-4 rounded my-48">
          Admin Panel
        </Link>
      </div>
    </div>
  );
};

export default Home;
