import React, { useEffect, useState } from 'react';
import FileUpload from './FileUpload';

const AdminDashboard = () => {
  const [questions, setQuestions] = useState([]);
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [editingAnswerId, setEditingAnswerId] = useState(null);
  const [editText, setEditText] = useState('');
  const [reload, setReload] = useState(false);
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [questionSet, setQuestionSet] = useState(1);
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    // Here you would check the password (in a real scenario, this should be done securely on the backend)
    if (password != '') { // Replace 'yourPassword' with your actual password
      setIsLoggedIn(true);
      fetchQuestions();
    } else {
      alert('Password not inputted!');
    }
  };
  const handleReload = () => {
    setReload(prev => !prev); // Toggle the state to force re-render
  };

  // useEffect to perform actions on reload
  useEffect(() => {
    fetchQuestions();
  }, [reload]); // Dependency on reload state



  const fetchQuestions = (set=0) => {
    if (set === 0) {
      set = questionSet;
    }
    fetch(`https://uni.xdd.ro/api/admin/questions/${set}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${password}`, // Ideally, use tokens instead of passwords
        'Content-Type': 'application/json'
      },
    
    })
      .then(res => res.json())
      .then(data => setQuestions(data))
      .catch(err => console.error(err));
  };

  const handleCheckboxChange = (questionId, answerId, isChecked) => {
    fetch('https://uni.xdd.ro/api/admin/update-answer', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${password}`, // Ideally, use tokens instead of passwords
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ questionId, answerId, isCorrect: isChecked }),
    })
    .then(res => {
      if (res.ok) {
        // Update the questions state to reflect the change
        setQuestions(questions => questions.map(q => {
          if (q.question_id === questionId) {
            return {
              ...q,
              answers: q.answers.map(a => a.answer_id === answerId ? { ...a, is_correct: isChecked } : a),
              hasCorrectAnswer: isChecked || q.answers.some(a => a.answer_id !== answerId && a.is_correct),
            };
          } else {
            return q;
          }
        }));
      }
      return res.json();
    })
    .then(data => console.log('Answer updated'))
    .catch(err => console.error(err));
  };


  const handleQuestionEdit = (questionId, newText) => {
    // Send the updated question text to the server
    fetch(`https://uni.xdd.ro/api/admin/update-question/${questionId}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${password}`, // Ideally, use tokens instead of passwords
        'Content-Type': 'application/json'
      },      body: JSON.stringify({ questionText: newText }),
    })
    .then(response => response.json())
    .then(data => {
      // Update local state to reflect changes
      setQuestions(questions.map(q => q.question_id === questionId ? { ...q, question_text: newText } : q));
      setEditingQuestionId(null);
    })
    .catch(err => console.error(err));
  };

  const handleAnswerEdit = (questionId, answerId, newText) => {
    // Send the updated answer text to the server
    fetch(`https://uni.xdd.ro/api/admin/update-answer/${answerId}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${password}`, // Ideally, use tokens instead of passwords
        'Content-Type': 'application/json'
      },      body: JSON.stringify({ answerText: newText }),
    })
    .then(response => response.json())
    .then(data => {
      // Update local state to reflect changes
      setQuestions(questions.map(q => q.question_id === questionId ? {
        ...q, 
        answers: q.answers.map(a => a.answer_id === answerId ? { ...a, answer_text: newText } : a)
      } : q));
      setEditingAnswerId(null);
    })
    .catch(err => console.error(err));
  };

  const handleRemoveAnswer = (questionId, answerId) => {
    // Send a request to remove the answer
    fetch(`https://uni.xdd.ro/api/admin/delete-answer/${answerId}`, { method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${password}`, // Ideally, use tokens instead of passwords
      'Content-Type': 'application/json'
    } })
    .then(response => response.json())
    .then(data => {
      // Update local state to reflect changes
      setQuestions(questions.map(q => q.question_id === questionId ? {
        ...q, 
        answers: q.answers.filter(a => a.answer_id !== answerId)
      } : q));
    })
    .catch(err => console.error(err));
  };

  const handleAddAnswer = (questionId) => {
    // Send a request to add a new answer
    fetch(`https://uni.xdd.ro/api/admin/add-answer`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${password}`, // Ideally, use tokens instead of passwords
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ questionId, newAnswerText: 'Double-Click to Change Text', isCorrect: false, questionSet: 1 }),
    })
    .then(response => response.json())
    .then(data => {
      // Update local state to include the new answer
      fetchQuestions();

    })
    .catch(err => console.error(err));
  };
  const HandleSetQuestionSet = (set) => {
    setQuestionSet(set);
    fetchQuestions(set);
  };

  if (!isLoggedIn) {
    return (
      <div className="flex items-center justify-center h-screen bg-blue-500">
        <form onSubmit={handlePasswordSubmit} className="p-10 bg-white rounded-lg shadow-xl">
          <div className="mb-6">
            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Your Password</label>
            <input
              type="password"
              id="password"
              name="password"
              required
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Login</button>
        </form>
      </div>
    );
  }
  return (
    <div className="container mx-auto p-4">
      <div className="mb-4">
      <div class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
        <p>Pentru a edita orice întrebare/răspuns, faceți clic pe text și introduceți noua valoare. Pentru a schimba care răspunsuri sunt adevărate/false, folosiți căsuțele de selectare. Pentru a adăuga/elimina întrebări, apăsați pe butoane.</p>
      </div>
      <div class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
       <p>Bármelyik kérdés/válasz szerkesztéséhez kattints a szövegre és írd be az új értéket. Az igaz/hamis válaszok megváltoztatásához használd a négyzeteket. Kérdések hozzáadásához/eltávolításához nyomd meg a gombokat.</p>
      </div>
      </div>
      {/* question set selector buttons */}
      <div className="mb-4">
        <button onClick={() => HandleSetQuestionSet(1)} className={`mx-2 px-4 py-2 rounded ${questionSet === 2 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}>Biologie</button>
        <button onClick={() => HandleSetQuestionSet(2)} className={`mx-2 px-4 py-2 rounded ${questionSet === 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}>Chimie</button>
      </div>
      {questions.map(question => (
        <div key={question.question_id} className="p-4 mb-4 border rounded shadow-sm">
          <h3 className={`text-lg font-semibold ${!question.hasCorrectAnswer ? 'text-red-500' : ''}`}>
            <b>{question.question_id}. </b>
            {editingQuestionId === question.question_id ? (
              <input 
                type="text" 
                className="w-auto px-2 py-1 border border-gray-300 focus:outline-none focus:border-black"
                style={{ minWidth: '100%', transition: 'width 0.4s ease-in-out' }}
                value={editText} 
                onChange={(e) => setEditText(e.target.value)}
                onBlur={() => handleQuestionEdit(question.question_id, editText)}
              />
            ) : (
              <span onClick={() => { setEditingQuestionId(question.question_id); setEditText(question.question_text); }}>
                {question.question_text}
              </span>
            )}
          </h3>
          {question.file ? (
              <img src={question.file.file_path} alt="Question file" className="max-w-full h-auto" />
            ) : (
              <FileUpload question_id={question.question_id} password={password} ReloadFunction={fetchQuestions}/>
            )}
      {question.answers
            .filter(answer => answer !== null || answer?.is_correct !== null ) // Filter out null answers
          .map(answer => (
            <div key={answer.answer_id} className="ml-4 my-2 flex items-center">
              <label>
                <input 
                  type="checkbox" 
                  className='mr-2 border border-gray-300 focus:outline-none focus:border-black'
                  checked={answer.is_correct} 
                  disabled={editingAnswerId !== null }
                  onChange={(e) => handleCheckboxChange(question.question_id, answer.answer_id, e.target.checked)}
                />
                {editingAnswerId === answer.answer_id ? (
                  <input 
                    type="text"
                    className='w-auto px-2 py-1 border border-gray-300 focus:outline-none focus:border-black' 
                    style={{ minWidth: '100%', transition: 'width 0.4s ease-in-out' }}
                    value={editText} 
                    onChange={(e) => setEditText(e.target.value)}
                    onBlur={() => handleAnswerEdit(question.question_id, answer.answer_id, editText)}
                  />
                ) : (
                  <span onClick={() => { setEditingAnswerId(answer.answer_id); setEditText(answer.answer_text); }}>
                    {answer.answer_text}
                  </span>
                )}
              </label>
              <button onClick={() => handleRemoveAnswer(question.question_id, answer.answer_id)} className="bg-red-500 text-white text-xs px-2 py-1 rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300 rounded my-2 mx-4">Remove</button>
            </div>
          ))}
          <button onClick={() => handleAddAnswer(question.question_id)} className="bg-green-500 text-white text-xs px-2 py-1 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 rounded my-2">Add Answer</button>
        </div>
      ))}
    </div>
  );
};

export default AdminDashboard;